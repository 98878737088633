import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'
// import SearchBox from '../../../components/SearchBox'

export default function KnowHowList() {
  const data = useStaticQuery(graphql`
    query {
      allCraftVidenInspirationTag {
        nodes {
          title
          slug
        }
      }
      allCraftVidenVidenEntry(sort: { fields: dateCreated, order: DESC }) {
        nodes {
          title
          uri
          videnInspiration {
            ... on Craft_videnInspiration_Tag {
              title
              slug
            }
          }
          dynamicContent {
            ... on Craft_dynamicContent_hero_BlockType {
              backgroundImage {
                title
                ... on Craft_productImages_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const GridItem = styled.div`
    @media (min-width: 1280px) {
      ${({ i }) => i === 0 && 'grid-column: 1 / span 2;'}
      ${({ i }) => i === 2 && 'grid-column: 1 / span 3;'}
  ${({ i }) => i === 4 && 'grid-column: 2 / span 2;'}
    }
    position: relative;
    min-height: 500px;
    background-color: #f1f1f1;
    transition: all 0.2s ease-in-out;
    ${tw`hover:shadow-lg hover:scale-110 transform transition ease-in duration-300 overflow-hidden`}
  `

  const Dropdown = styled.select`
    ${tw`uppercase
    text-left
    border-2
    py-1
    px-4
    border-sand
    focus:outline-none
    mr-2
    w-full
    lg:w-64
    appearance-none
    bg-sand
  `}
  `

  const TagList = ({ tags, onChange, tagfilter }) => {
    return (
      /* eslint-disable jsx-a11y/no-onchange */
      <div css={tw`relative inline-block`}>
        <Dropdown name="tags" onChange={onChange} value={tagfilter}>
          <option value="">Vis alle emner</option>
          {tags.map((tag, i) => {
            return (
              <option key={i} value={tag.slug}>
                {tag.title}
              </option>
            )
          })}
        </Dropdown>
        <div
          css={tw`absolute right-0 flex items-center pr-2 lg:pr-6 pointer-events-none top-0 mt-3 `}
        >
          <svg css={tw`w-4 h-4 fill-current`} viewBox="0 0 20 20">
            <path
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
              fillRule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    )
  }

  const [search, setSearch] = useState('')
  const [tagfilter, setTagfilter] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search !== '') {
      setTagfilter(window.location.search.replace('?', '').toLowerCase())
    }
    return () => {
      setTagfilter('')
    }
  }, [])

  const videnEntries = data.allCraftVidenVidenEntry.nodes
  const tags = data.allCraftVidenInspirationTag.nodes

  return (
    <div>
      <div css={tw`mb-4 flex justify-end container md:px-10 px-6 border-navy`}>
        <TagList
          tags={tags}
          onChange={(e) => setTagfilter(e.target.value)}
          tagfilter={tagfilter}
        />
        <input
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Filter fritekst"
          type="text"
          css={tw`uppercase
            text-left
            border-2
            border-sand
            bg-sand
            px-4
            focus:outline-none
            ml-2
            lg:w-64
            appearance-none
          `}
        />
      </div>
      <section
        className="grid"
        css={tw`grid md:grid-cols-2 xl:grid-cols-3 gap-8 container mb-16 md:px-10 px-6`}
      >
        {videnEntries
          .filter((v) => {
            const tags = v.videnInspiration.map((t) => t.slug)
            return (
              v.title.toLowerCase().includes(search.toLowerCase()) &&
              (tags.includes(tagfilter) || tagfilter === '')
            )
          })
          .map((v, i) => {
            return (
              <GridItem key={i} i={i} className={`gridItem ${i}`}>
                <Link to={'/' + v.uri} css={tw`w-full h-full flex flex-col`}>
                  {v.dynamicContent[0]?.backgroundImage[0]?.localFile
                    .childImageSharp.gatsbyImageData && (
                    <GatsbyImage
                      image={
                        v.dynamicContent[0]?.backgroundImage[0]?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={v.dynamicContent[0]?.backgroundImage[0]?.title}
                      css={tw`absolute w-full h-full`}
                    />
                  )}
                  <h3
                    css={[
                      `
                          text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
                        `,
                      tw`px-8 py-8 text-white relative z-10 text-4xl`,
                    ]}
                  >
                    {v.title}
                  </h3>
                </Link>
              </GridItem>
            )
          })}
      </section>
    </div>
  )
}
